import React, { useState } from 'react';
import classes from './ImageGallery.scss';

function ImageGallery() {
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isEnlarged, setIsEnlarged] = useState(false);
    const images = [
        'https://www.dropbox.com/s/v854fhh9g35qjfc/modern_mh-6_little_bird.jpg?raw=1',
	    'https://www.dropbox.com/s/n788wnzwwkqr1qf/modern_guns.jpg?raw=1',
        'https://www.dropbox.com/s/vlh9kb7q99i9my0/modern_eletronic_devices.jpg?raw=1',
        'https://www.dropbox.com/s/nibcrcmnepg91rp/modern_atm_machine.jpg?raw=1'
    ];
    const headings = [
        'MH-6 Helicopter (Little Bird)',
        'Modern-Era Firearms',
        'Eletronic Devices',
        'ATM Machine'
      ];
  
      const previousImage = () => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
        setIsEnlarged(false);
      };
    
      const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsEnlarged(false);
      };
  
    const enlargeImage = () => {
        setIsEnlarged((prevEnlarged) => !prevEnlarged);
      };
  
    return (
      <div>
          <h4 className="button">
        <button onClick={previousImage}>Previous</button>
        <button onClick={nextImage}>Next</button>
        
        </h4>
        <h5>{headings[currentImageIndex]}</h5>
          <h4>
          <img
          src={images[currentImageIndex]}
          alt="Gallery Image"
          className={isEnlarged ? 'enlarged' : 'small'}
          onClick={enlargeImage}
        />
          </h4>
          
      </div>
    );
  }
  export default ImageGallery;