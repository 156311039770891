import React, { useState } from 'react';
import classes from './ImageGallery.scss';

function ImageGallery() {
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isEnlarged, setIsEnlarged] = useState(false);
    const images = [
        'https://www.dropbox.com/s/fj195xu1i3j8op9/lp_work_tools.jpg?raw=1',
	    'https://www.dropbox.com/s/u3ssawh8rxglmme/lp_living_space.jpg?raw=1',
        'https://www.dropbox.com/s/1fixhn6mf5sb2mi/lp_furniture.jpg?raw=1',
        'https://www.dropbox.com/s/mo90xnpv9vo2m19/lp_lab_equipment.jpg?raw=1'
    ];
    const headings = [
        'Computer Desk, Work Bench, and Generator',
        'Fireplace, TV, Piano, and Night-Table',
        'Cabinet, Desk and Hall-Table',
        'Lab Equipment'
      ];
  
      const previousImage = () => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
        setIsEnlarged(false);
      };
    
      const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsEnlarged(false);
      };
  
    const enlargeImage = () => {
        setIsEnlarged((prevEnlarged) => !prevEnlarged);
      };
  
    return (
      <div>
          <h4 className="button">
        <button onClick={previousImage}>Previous</button>
        <button onClick={nextImage}>Next</button>
        </h4>
        <h5>{headings[currentImageIndex]}</h5>
          <h4>
          <img
          src={images[currentImageIndex]}
          alt="Gallery Image"
          className={isEnlarged ? 'enlarged' : 'small'}
          onClick={enlargeImage}
        />
          </h4>
          
      </div>
    );
  }
  export default ImageGallery;