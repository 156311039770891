import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { Navbar, NavbarBrand } from 'reactstrap';
//import Menu from './components/menu';
import { PLACES } from './shared/places';
//import Greet from './components/greet';
import Tabs from "./components/Tabs";
import ReactPlayer from "react-player";
import image from './images/2.png';
import ImageGallery from './components/ImageGallery';
import RealisticGallery from './components/RealisticGallery';
class App extends React.Component {					
  
	constructor(props){
		super(props);
		
		this.state = {
			places: PLACES
		};
	}

	render(){

		return (
			<div
			/*
			style={{
				backgroundColor: 'red'
			  }}
			  */
			>
			  {/*<h1>Jeremy Eustace, Software Developer</h1>*/}
			  <img src={image} height={225} width={750} />
			  <Tabs>
				{/*---------------------- ABOUT ---------------------*/}
				<div label="About">
				<h2>About</h2>

				<p>Creating something from start-to-finish is incredibly rewarding and I am so grateful that I have been able to establish a skill set that allows me to do so in such a dynamic and engaging way. I have always loved computers and when the opportunity came up to study Computer Science at Memorial University, I could not say no.
				</p>
				<p>I had been dabbling in software development for years before pursuing my formal education, and the schooling really helped me take my skill set to the next level. I studied game design (a passion of mine), software engineering, data science, machine learning, web development, 3D graphic programming, and I also fine-tuned my programming skills using C#, JavaScript, Python, and a full suite of others.
				</p>
				<p>I have been working on a number of software development projects both with classmates and independently. Working in different conditions with others and on my own have provided a wealth of different experience in which I have gained critical technical and interpersonal skills that I look forward to utilizing in an established professional environment.
				</p>
				<p>When I’m not hard at work, I enjoy playing video games, exercising, and of course, spending time with friends and family.
			  	</p>
				</div>
				{/*---------------------- PROGRAMMING ---------------------*/}
				<div label="Programming">
				{/*------------- Python -------------*/}
				<h3><a href="https://github.com/jce77/EZFolderBackup">EZ Folder Backup</a></h3>
				<h4>A versatile free open-source local file backup tool
				for Windows and Linux operating systems with a graphical interface for standard use and
				extensive command-line run parameters for advanced use. The latest version can be found 
				at <a href="https://github.com/jce77/EZFolderBackup">https://github.com/jce77/EZFolderBackup</a>.

				</h4>

				<h3>Graphical Interface Usage Video</h3>
				<p>
				<ReactPlayer 
					url="https://youtu.be/3_gKugIbbsE" 
					className="react-player" 
					controls="true"
				/>
				</p>
				<h3>Command-Line Usage Video</h3>
				<p>
				<ReactPlayer 
					url="https://youtu.be/gIA1575mpTo" 
					className="react-player" 
					controls="true"
				/>
				</p>
				
				</div>
				{/*--------------------- Game Development --------------------*/}
				<div label="Game Development">
				<h2>Dungeons of Havoc, a Unity3D Engine game:</h2>
				<h4><a href="https://www.youtube.com/watch?v=9qWHbzQ-XE8">Dungeons of Havoc - Public Alpha Release I Video</a>
				<ReactPlayer 
					url="https://www.youtube.com/watch?v=9qWHbzQ-XE8" 
					className="react-player"
					controls="true"
				/>
				Try current demo version on your browser for free at <a href="https://stonehammergames.itch.io/dungeons-of-havoc">https://stonehammergames.itch.io/dungeons-of-havoc</a>, no installation needed. 
				</h4>
				
				<h2>City Survival, a Unity3D Engine game:</h2>
				<h4><a href="https://www.youtube.com/watch?v=KNzT7jzDwF0">Third update video</a>
				<ReactPlayer 
					url="https://www.youtube.com/watch?v=KNzT7jzDwF0" 
					className="react-player"
					controls="true"
				/>
				</h4>

				{/*REMOVED "https://youtu.be/v8dvF5SVqhM">Space Invaders(1977)*/}
				{/*REMOVED "https://www.youtube.com/watch?v=KNzT7jzDwF0">City Survival: Third Dev Update</a>, eighteen months into the development of my latest video game project.*/}
				</div>
				{/*--------------------- 3D Graphic Design --------------------*/}
				<div label="3D Graphic Design">
				<h3 className="centered_heading"> Low-Poly Style Samples </h3>
				<h4>
				<ImageGallery />
				</h4>
				<h3  className="centered_heading"> Realistic-Style Samples </h3>
				<h4>
				<RealisticGallery />
				</h4>
				</div>
				{/*--------------------- CONTACT --------------------*/}
				<div label="Contact">
				<h2>Contact</h2>
				  <p>Email: <a href="mailto:jce7@protonmail.com">jce7@protonmail.com</a></p>
				  <p>LinkedIn: <a href="https://www.linkedin.com/in/jeremy-eustace709/">linkedin.com/in/jeremy-eustace709</a></p>
				</div>
				{/*--------------------------------------------------*/}
			  </Tabs>
			</div>
		  );

		// from original tutorial
		/*

				OLD HEADER


		<Navbar color="primary">
					<div className="container" id="brand">
						<NavbarBrand>City Survival</NavbarBrand>
					</div>s
				</Navbar>
				




		return (
			<div className='App'>
				<Navbar color="primary">
					<div className="container" id="brand">
						<NavbarBrand>City Survival</NavbarBrand>
					</div>
				</Navbar>
				<Greet />
				<h1>Here are some coloured squares</h1>
				<Menu places={this.state.places} />
			</div>
		);
		*/
	}
}

export default App;