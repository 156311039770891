
export const PLACES = 
[
        {
            id: 0,
            name: 'Image 1',
            image: 'assets/images/1.jpg',
            category: 'Hills',
            description: "description text",
            source: 'description of source'
        },{
            id: 1,
            name: 'Image 2',
            image: 'assets/images/2.jpg',
            category: 'Plains',
            description: "description text",
            source: 'description of source'
        },{
            id: 2,
            name: 'Image 3',
            image: 'assets/images/3.jpg',
            category: 'Plateau',
            description: "description text",
            source: 'description of source'
        },{
            id: 3,
            name: 'Image 4',
            image: 'assets/images/4.jpg',
            category: 'Plains',
            description: "description text",
            source: 'description of source'
        },{
            id: 4,
            name: 'Image 5',
            image: 'assets/images/5.jpg',
            category: 'Coastal',
            description: "description text",
            source: 'wikipedia'
        },{
            id: 5,
            name: 'Image 6',
            image: 'assets/images/6.jpg',
            category: 'Hills',
            description: "description text",
            source: 'wikipedia'
        },{
            id: 6,
            name: 'Image 7',
            image: 'assets/images/7.jpg',
            category: 'Hills',
            description: "description text",
            source: 'wikipedia'
        },{
            id: 7,
            name: 'Image 8',
            image: 'assets/images/8.jpg',
            category: 'Plains',
            description: "description text",
            source: 'wikipedia'
        },{
            id: 8,
            name: 'Image 9',
            image: 'assets/images/9.jpg',
            category: 'Plains',
            description: "description text",
            source: 'wikipedia'
        }

];